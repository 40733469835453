<template>
  <Wizard :wizard-steps="steps" />
</template>

<script>
import Wizard from "@/components/Wizard/Wizard.vue";
import UserStep, {
  step as userStepConfig
} from "./components/UserStep/UserStep.vue";
import AccessStep, {
  step as accessStepConfig
} from "./components/AccessStep/AccessStep.vue";
import OrganizationStep, {
  step as organizationStepConfig
} from "./components/OrganizationStep/OrganizationStep.vue";

export default {
  components: { Wizard },
  computed: {
    steps() {
      return [
        { ...userStepConfig, component: UserStep },
        { ...accessStepConfig, component: AccessStep },
        { ...organizationStepConfig, component: OrganizationStep }
      ];
    }
  }
};
</script>

<style lang="css" scoped></style>
