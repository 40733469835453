var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormItem',{staticClass:"mb-0"},[_c('Checkbox',{on:{"on-change":function (state) { return (_vm.password = state ? '' : _vm.generatePassword()); }},model:{value:(_vm.sendEmailPassword),callback:function ($$v) {_vm.sendEmailPassword=$$v},expression:"sendEmailPassword"}},[_vm._v(" "+_vm._s(_vm.$t("SEND_A_PASSWORD_CONFIGURATION_EMAIL_RECOMMENDED"))+" ")])],1),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"ivu-form-item-label"},[_vm._v(_vm._s(_vm.$t("PASSWORD")))]),_c('div',{staticClass:"flex-grow ivu-form-item-label"},[_c('span',{staticClass:"text-gray-400",domProps:{"innerHTML":_vm._s(_vm.$t('PASSWORD_TO_MANUALLY_PROVIDE'))}})])]),_c('ValidationProvider',{attrs:{"slim":"","name":("\"" + (_vm.$t('PASSWORD')) + "\""),"rules":{
      required: !_vm.sendEmailPassword,
      min: 20,
      regex: /\S/
    },"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var pristine = ref.pristine;
    var errors = ref.errors;
return [_c('FormItem',{attrs:{"error":pristine ? '' : errors[0]}},[_c('Input',{attrs:{"placeholder":_vm.$t('PASSWORD'),"disabled":_vm.sendEmailPassword},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('Button',{attrs:{"type":"text","ghost":"","disabled":_vm.sendEmailPassword},on:{"click":function($event){_vm.password = _vm.generatePassword()}}},[_vm._v(" "+_vm._s(_vm.$t("GENERATE"))+" ")]),_c('Divider',{attrs:{"type":"vertical"}}),_c('Button',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(function () { return _vm.password; }),expression:"() => password"}],attrs:{"type":"text","ghost":"","disabled":_vm.sendEmailPassword},on:{"click":function($event){_vm.$Notice.info({
                title: _vm.$t('COPIED_IN_CLIPBOARD'),
                duration: 1.5
              })}}},[_vm._v(" "+_vm._s(_vm.$t("COPY"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }