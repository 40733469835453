<template>
  <ValidationObserver tag="div" v-slot="{ invalid }" class="flex flex-col">
    <Card dis-hover class="my-5 flex-grow">
      <Error :error="error">
        <h1 class="pb-3">
          {{ $t("WHICH_ORGANIZATION_IS_THIS_USER_A_MEMBER_OF") }}
        </h1>
        <Form label-position="top" @submit.native.prevent>
          <SelectOrganization v-model="organization" :disabled="loading" />

          <div v-if="newOrganization">
            <h1 class="pb-3">{{ $t("CAN_I_HAVE_SOME_EXTRA_INFORMATION") }}</h1>

            <DemoCheckbox
              v-model="demo"
              :disabled="loading"
              @input="handleCheckDemo"
            />

            <SelectQlikApplications
              v-model="selectedQlikApps"
              :disabled="loading"
            />
            <SetExpirationDate
              v-model="daysBeforeExpiration"
              :new-organization="newOrganization"
              :disabled="loading"
            />
          </div>

          <OrganizationInformation
            v-if="organization && !newOrganization"
            class="mt-10"
            :organization="organization"
            :new-organization="newOrganization"
          />
        </Form>
      </Error>
    </Card>
    <NavigationBar
      :back-button-disabled="loading"
      :next-button-disabled="invalid"
      :next-button-loading="loading"
      :next-button-click="onboardUser"
    />
  </ValidationObserver>
</template>

<script>
import NavigationBar from "@/components/Wizard/NavigationBar/NavigationBar.vue";
import { ValidationObserver } from "vee-validate-full";
import SelectOrganization from "./components/SelectOrganization/SelectOrganization.vue";
import OrganizationInformation from "./components/OrganizationInformation/OrganizationInformation.vue";
import DemoCheckbox from "@/Organizations/OnboardOrganization/components/DemoCheckbox/DemoCheckbox.vue";
import SelectQlikApplications from "@/Organizations/OnboardOrganization/components/SelectQlikApplications/SelectQlikApplications.vue";
import SetExpirationDate from "@/Organizations/OnboardOrganization/components/SetExpirationDate/SetExpirationDate.vue";
import qlikAppsQuery from "./queries/qlikApps.gql";
import onboardUser from "./queries/onboardUser.gql";
import Error from "@/components/Error/Error.vue";
import { createNamespacedHelpers } from "vuex";
import { push } from "@/helpers/breadcrumb.js";

const { mapState } = createNamespacedHelpers("wizard");

export const step = {
  titleKey: "ORGANIZATION",
  descKey: "WHICH_ORGANIZATION_IS_THIS_USER_A_MEMBER_OF",
  data: {
    organization: null,
    demo: false,
    selectedQlikApps: [],
    daysBeforeExpiration: 0
  }
};
export default {
  components: {
    NavigationBar,
    ValidationObserver,
    SelectOrganization,
    OrganizationInformation,
    DemoCheckbox,
    SelectQlikApplications,
    SetExpirationDate,
    Error
  },
  props: { value: { type: Object } },
  data: () => ({ loading: false, error: null, qlikApps: [] }),
  apollo: {
    qlikApps: {
      query: qlikAppsQuery,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  computed: {
    // For each value in step.data,
    // generate a computed property with setter/getter for v-model binding
    ...Object.keys(step.data).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          get() {
            return this.value[key];
          },
          set(value) {
            this.$emit("input", { ...this.value, [key]: value });
          }
        }
      }),
      {}
    ),
    newOrganization() {
      const { organization } = this;
      return organization && organization.id === organization.displayName;
    },
    ...mapState(["steps"])
  },
  methods: {
    handleCheckDemo(checked) {
      if (!checked || this.selectedQlikApps[0]) return;
      const producerApp = this.qlikApps.find(
        ({ name }) => name === "odag_producer"
      );
      this.selectedQlikApps = [producerApp];
    },
    onboardUser() {
      this.loading = true;

      const {
        email,
        name,
        activation,
        ignition,
        relation,
        organization,
        password,
        selectedQlikApps,
        demo,
        daysBeforeExpiration
      } = this.steps.reduce((acc, { data }) => ({ ...acc, ...data }), {});

      const qlikAppIds = selectedQlikApps.map(({ id }) => id);

      this.$apollo
        .mutate({
          mutation: onboardUser,
          variables: {
            email,
            name,
            assignRoles: [
              ...(activation ? ["activation_user"] : []),
              ...(ignition ? ["ignition_user"] : []),
              ...(relation ? ["relation_user"] : []),
              "switcher"
            ],
            tenantName: organization.name,
            password: password || undefined,
            ...(this.newOrganization && {
              tenantCreationGrantQlikAppsIds: qlikAppIds,
              tenantCreationMode: demo ? "DEMO" : "STANDARD",
              tenantCreationRolesExpireInDays: daysBeforeExpiration,
              tenantCreationDisplayName: organization.displayName
            })
          }
        })
        .then(({ data: { onboardUser: { id, name } } }) =>
          push(
            { user: name },
            { name: "userGeneralTab", params: { userId: id } }
          )
        )
        .catch(
          ({ graphQLErrors, networkError }) =>
            (this.error = JSON.stringify(networkError || graphQLErrors))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
