<template>
  <ValidationObserver tag="div" v-slot="{ invalid }" class="flex flex-col">
    <Card dis-hover class="my-5 flex-grow">
      <h1 class="mb-5">{{ $t("WHO_DO_WE_WANT_TO_WELCOME") }}</h1>
      <Form label-position="top" @submit.native.prevent>
        <div class="flex">
          <label class="ivu-form-item-label">{{ $t("EMAIL") }}</label>
          <div class="flex-grow ivu-form-item-label">
            <span class="text-gray-400">
              {{ $t("USED_AS_LOGIN_IDENTIFIER") }}
            </span>
          </div>
        </div>
        <ValidationProvider
          slim
          :name="`&quot;${$t('EMAIL')}&quot;`"
          rules="required|email|already_exist"
          immediate
          v-slot="{ pristine, errors }"
        >
          <FormItem :error="pristine ? '' : errors[0]">
            <Input v-model="email" :placeholder="$t('EMAIL_PLACEHOLDER')" />
          </FormItem>
        </ValidationProvider>
        <div class="flex">
          <label class="ivu-form-item-label">{{ $t("NAME") }}</label>
          <div class="flex-grow ivu-form-item-label">
            <span class="text-gray-400">
              {{ $t("FULL_NAME_THAT_WILL_BE_DISPLAYED_FOR_THIS_PERSON") }}
            </span>
          </div>
        </div>
        <ValidationProvider
          slim
          :name="`&quot;${$t('NAME')}&quot;`"
          rules="required"
          immediate
          v-slot="{ pristine, errors }"
        >
          <FormItem :error="pristine ? '' : errors[0]">
            <Input v-model="name" placeholder="John Do" />
          </FormItem>
        </ValidationProvider>
        <SetPasswordMethod v-model="password" />
      </Form>
    </Card>
    <NavigationBar :next-button-disabled="invalid" />
  </ValidationObserver>
</template>

<script>
import NavigationBar from "@/components/Wizard/NavigationBar/NavigationBar.vue";
import {
  extend,
  ValidationObserver,
  ValidationProvider
} from "vee-validate-full";
import SetPasswordMethod from "./components/SetPasswordMethod/SetPasswordMethod.vue";
import usersQuery from "./queries/users.gql";

export const step = {
  titleKey: "USER",
  descKey: "WHO_DO_WE_WANT_TO_WELCOME",
  data: { email: "", name: "", password: "" }
};

export default {
  components: {
    NavigationBar,
    ValidationObserver,
    ValidationProvider,
    SetPasswordMethod
  },
  props: { value: { type: Object } },
  mounted() {
    extend("already_exist", {
      message: this.$t("THIS_USER_ALREADY_EXIST"),
      validate: userEmail =>
        this.users.every(({ email }) => email !== userEmail)
    });
  },
  apollo: {
    users: {
      query: usersQuery,
      update: ({ users: { entries = [] } = {} }) => entries
    }
  },
  computed: {
    // For each value in step.data,
    // generate a computed property with setter/getter for v-model binding
    ...Object.keys(step.data).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          get() {
            return this.value[key];
          },
          set(value) {
            this.$emit("input", { ...this.value, [key]: value });
          }
        }
      }),
      {}
    )
  }
};
</script>

<style scoped></style>
