<template>
  <div class="text-right">
    <ButtonGroup>
      <Button
        @click="backClick"
        :loading="backButtonLoading"
        :disabled="!currentStep || backButtonDisabled"
      >
        <fa icon="angle-left" class="mr-1 align-sub"></fa>
        {{ $t("BACK") }}
      </Button>
      <Button
        type="primary"
        @click="nextClick"
        :loading="nextButtonLoading"
        :disabled="nextButtonDisabled"
      >
        {{ $t(steps[currentStep + 1] ? "NEXT" : "FINISH") }}
        <fa
          v-show="steps[currentStep + 1]"
          icon="angle-right"
          class="ml-1 align-sub"
        ></fa>
      </Button>
    </ButtonGroup>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations } = createNamespacedHelpers("wizard");

export default {
  props: {
    backButtonLoading: { type: Boolean, default: false },
    backButtonDisabled: { type: Boolean, default: false },
    backButtonClick: { type: Function },
    nextButtonLoading: { type: Boolean, default: false },
    nextButtonDisabled: { type: Boolean, default: false },
    nextButtonClick: { type: Function }
  },
  computed: {
    ...mapState(["steps", "currentStep"]),
    backClick() {
      return this.backButtonClick || this.back;
    },
    nextClick() {
      return this.nextButtonClick || this.next;
    }
  },
  methods: mapMutations(["back", "next"])
};
</script>

<style scoped>
.align-sub {
  vertical-align: sub;
}
</style>
