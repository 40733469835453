function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Error',{attrs:{"error":_vm.error}},[_c('h1',{staticClass:"pb-3"},[_vm._v(_vm._s(_vm.$t("INFORMATION_ABOUT_THIS_ORGANIZATION")))]),_c('div',{staticClass:"flow-root"},[_c('label',{staticClass:"ivu-form-item-label"},[_vm._v(" "+_vm._s(_vm.$t("QLIK_APPLICATIONS"))+" ")])]),_c('div',{staticClass:"mb-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tenant.qlikApps.length),expression:"!tenant.qlikApps.length"}],staticClass:"text-yellow-500"},[_c('fa',{attrs:{"icon":"exclamation-triangle"}}),_vm._v(" "+_vm._s(_vm.$t("NONE"))+" ")],1),_vm._l((_vm.tenant.qlikApps),function(ref){
var id = ref.id;
var name = ref.name;
return _c('Tag',{key:id,staticClass:"cursor-default",attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(name)+" ")])})],2),_c('div',{staticClass:"flow-root"},[_c('label',{staticClass:"ivu-form-item-label"},[_vm._v(_vm._s(_vm.$t("ROLES")))])]),_c('div',{staticClass:"roles-container"},_vm._l((_vm.tenant.roles),function(ref){
var id = ref.id;
var rest$1 = objectWithoutProperties( ref, ["id"] );
var rest = rest$1;
return _c('div',{key:id,staticClass:"flex items-start"},[_c('Role',_vm._b({},'Role',Object.assign({}, {id: id}, rest),false))],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }