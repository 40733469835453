<template>
  <Error :error="error">
    <h1 class="pb-3">{{ $t("INFORMATION_ABOUT_THIS_ORGANIZATION") }}</h1>
    <div class="flow-root">
      <label class="ivu-form-item-label">
        {{ $t("QLIK_APPLICATIONS") }}
      </label>
    </div>
    <div class="mb-4">
      <span v-show="!tenant.qlikApps.length" class="text-yellow-500">
        <fa icon="exclamation-triangle"></fa>
        {{ $t("NONE") }}
      </span>
      <Tag
        v-for="{ id, name } in tenant.qlikApps"
        :key="id"
        color="blue"
        class="cursor-default"
      >
        {{ name }}
      </Tag>
    </div>
    <div class="flow-root">
      <label class="ivu-form-item-label">{{ $t("ROLES") }}</label>
    </div>
    <div class="roles-container">
      <div
        v-for="{ id, ...rest } in tenant.roles"
        :key="id"
        class="flex items-start"
      >
        <Role v-bind="{ id, ...rest }" />
      </div>
    </div>
  </Error>
</template>

<script>
import tenantQuery from "./queries/tenant.gql";
import Error from "@/components/Error/Error.vue";
import Role from "./components/Role/Role.vue";

export default {
  components: { Error, Role },
  props: {
    organization: { type: Object, default: null },
    newOrganization: { type: Boolean, default: false }
  },
  data: () => ({ tenant: { qlikApps: [], roles: [] }, error: null }),
  apollo: {
    tenant: {
      query: tenantQuery,
      variables() {
        return { id: this.organization.id };
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      },
      skip() {
        return !this.organization || this.newOrganization;
      }
    }
  }
};
</script>

<style scoped>
.roles-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  grid-gap: 2rem;
}
</style>
