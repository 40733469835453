<template>
  <div>
    <FormItem class="mb-0">
      <Checkbox
        v-model="sendEmailPassword"
        @on-change="state => (password = state ? '' : generatePassword())"
      >
        {{ $t("SEND_A_PASSWORD_CONFIGURATION_EMAIL_RECOMMENDED") }}
      </Checkbox>
    </FormItem>
    <div class="flex">
      <label class="ivu-form-item-label">{{ $t("PASSWORD") }}</label>
      <div class="flex-grow ivu-form-item-label">
        <span
          class="text-gray-400"
          v-html="$t('PASSWORD_TO_MANUALLY_PROVIDE')"
        />
      </div>
    </div>
    <ValidationProvider
      slim
      :name="`&quot;${$t('PASSWORD')}&quot;`"
      :rules="{
        required: !sendEmailPassword,
        min: 20,
        regex: /\S/
      }"
      immediate
      v-slot="{ pristine, errors }"
    >
      <FormItem :error="pristine ? '' : errors[0]">
        <Input
          v-model="password"
          :placeholder="$t('PASSWORD')"
          :disabled="sendEmailPassword"
        >
          <template #append>
            <Button
              type="text"
              ghost
              @click="password = generatePassword()"
              :disabled="sendEmailPassword"
            >
              {{ $t("GENERATE") }}
            </Button>
            <Divider type="vertical" />
            <Button
              type="text"
              ghost
              @click="
                $Notice.info({
                  title: $t('COPIED_IN_CLIPBOARD'),
                  duration: 1.5
                })
              "
              v-clipboard="() => password"
              :disabled="sendEmailPassword"
            >
              {{ $t("COPY") }}
            </Button>
          </template>
        </Input>
      </FormItem>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate-full";
import generatePassword from "password-generator";

export default {
  components: { ValidationProvider },
  props: { value: { type: String, default: "" } },
  data() {
    return { sendEmailPassword: !this.value };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    generatePassword: () => generatePassword(20, false, /[A-Za-z0-9]/)
  }
};
</script>

<style scoped></style>
