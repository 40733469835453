import router from "@/router.js";
import store from "@/store";

export const push = (breadcrumbParams, routerParams) => {
  store.commit("breadcrumb/addAssets", breadcrumbParams);
  router.push(routerParams);
};

export const replace = (breadcrumbParams, routerParams) => {
  store.commit("breadcrumb/addAssets", breadcrumbParams);
  router.replace(routerParams);
};

export default { push, replace };
