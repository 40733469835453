<template>
  <div class="h-full flex flex-col">
    <Steps :current="currentStep">
      <Step
        v-for="({ titleKey, descKey }, index) in steps"
        :key="index"
        :title="$t(titleKey)"
        :content="$t(descKey)"
      />
    </Steps>
    <component
      :is="steps[currentStep].component"
      v-model="stepData"
      class="flex-grow"
    />
  </div>
</template>

<script>
import wizardStore from "./wizard.store.js";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations } = createNamespacedHelpers(
  "wizard"
);

export default {
  props: { wizardSteps: { type: Array, required: true } },
  beforeCreate() {
    this.$store.registerModule("wizard", wizardStore);
  },
  created() {
    this.setSteps(this.wizardSteps);
  },
  computed: {
    ...mapState(["steps", "currentStep"]),
    ...mapGetters(["currentStepData"]),
    stepData: {
      get() {
        return this.currentStepData;
      },
      set(stepData) {
        this.setCurrentStepData(stepData);
      }
    }
  },
  methods: mapMutations(["setSteps", "setCurrentStepData"]),
  destroyed() {
    this.$store.unregisterModule("wizard");
  }
};
</script>

<style scoped></style>
