var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"flex flex-col",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('Card',{staticClass:"my-5 flex-grow",attrs:{"dis-hover":""}},[_c('h1',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("WHO_DO_WE_WANT_TO_WELCOME")))]),_c('Form',{attrs:{"label-position":"top"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"flex"},[_c('label',{staticClass:"ivu-form-item-label"},[_vm._v(_vm._s(_vm.$t("EMAIL")))]),_c('div',{staticClass:"flex-grow ivu-form-item-label"},[_c('span',{staticClass:"text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t("USED_AS_LOGIN_IDENTIFIER"))+" ")])])]),_c('ValidationProvider',{attrs:{"slim":"","name":("\"" + (_vm.$t('EMAIL')) + "\""),"rules":"required|email|already_exist","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var errors = ref.errors;
return [_c('FormItem',{attrs:{"error":pristine ? '' : errors[0]}},[_c('Input',{attrs:{"placeholder":_vm.$t('EMAIL_PLACEHOLDER')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)}),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"ivu-form-item-label"},[_vm._v(_vm._s(_vm.$t("NAME")))]),_c('div',{staticClass:"flex-grow ivu-form-item-label"},[_c('span',{staticClass:"text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t("FULL_NAME_THAT_WILL_BE_DISPLAYED_FOR_THIS_PERSON"))+" ")])])]),_c('ValidationProvider',{attrs:{"slim":"","name":("\"" + (_vm.$t('NAME')) + "\""),"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var errors = ref.errors;
return [_c('FormItem',{attrs:{"error":pristine ? '' : errors[0]}},[_c('Input',{attrs:{"placeholder":"John Do"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('SetPasswordMethod',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('NavigationBar',{attrs:{"next-button-disabled":invalid}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }