const state = () => ({ steps: [], currentStep: 0 });

const getters = {
  currentStepData: ({ steps, currentStep }) => steps[currentStep].data
};

const mutations = {
  next: state => state.steps[state.currentStep + 1] && state.currentStep++,
  back: state => state.currentStep && state.currentStep--,
  setSteps: (state, steps) => (state.steps = steps),
  setCurrentStepData: ({ steps, currentStep }, data) =>
    (steps[currentStep].data = data)
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
