<template>
  <div class="flex flex-col">
    <Card dis-hover class="my-5 flex-grow">
      <h1 class="mb-5">{{ $t("WHAT_CAN_THIS_USER_ACCESS") }}</h1>
      <Form :label-width="80">
        <FormItem label="Ignition">
          <i-switch id="ignition" v-model="ignition" />
        </FormItem>
        <FormItem label="Activation">
          <i-switch id="activation" v-model="activation" />
        </FormItem>
        <FormItem label="Relation">
          <i-switch id="relation" v-model="relation" />
        </FormItem>
      </Form>
    </Card>
    <NavigationBar />
  </div>
</template>

<script>
import NavigationBar from "@/components/Wizard/NavigationBar/NavigationBar.vue";

export const step = {
  titleKey: "ACCESS",
  descKey: "WHAT_CAN_THIS_USER_ACCESS",
  data: { ignition: true, activation: true, relation: true }
};
export default {
  components: { NavigationBar },
  props: { value: { type: Object } },
  computed: {
    // For each value in step.data,
    // generate a computed property with setter/getter for v-model binding
    ...Object.keys(step.data).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          get() {
            return this.value[key];
          },
          set(value) {
            this.$emit("input", { ...this.value, [key]: value });
          }
        }
      }),
      {}
    )
  }
};
</script>

<style scoped></style>
