<template>
  <Error :error="error">
    <div class="flex">
      <label class="ivu-form-item-label">{{ $t("ORGANIZATION") }}</label>
      <div class="flex-grow ivu-form-item-label">
        <span class="text-gray-400">
          {{
            $t("GENERALLY_THE_NAME_OF_THE_COMPANY_TO_WHICH_THIS_PERSON_BELONGS")
          }}
        </span>
      </div>
    </div>
    <ValidationProvider
      slim
      :name="`&quot;${$t('ORGANIZATION')}&quot;`"
      rules="required"
      immediate
      v-slot="{ pristine, errors }"
    >
      <FormItem :error="pristine ? '' : errors[0]">
        <AutoComplete
          v-model="organization"
          :placeholder="$t('CHOOSE_OR_CREATE_AN_ORGANIZATION')"
          :disabled="disabled"
          :data="organizations"
          clearable
        />
      </FormItem>
    </ValidationProvider>
  </Error>
</template>

<script>
import { ValidationProvider } from "vee-validate-full";
import tenantsQuery from "./queries/tenants.gql";
import Error from "@/components/Error/Error.vue";
import { escapeRegExp, snakeCase } from "lodash";

function Organization({ id, name, displayName }) {
  Object.assign(this, {
    id: id || displayName,
    name: name || snakeCase(displayName),
    displayName
  });
}

export default {
  components: { ValidationProvider, Error },
  props: {
    value: { type: Object, default: null },
    disabled: { type: Boolean, default: false }
  },
  data: ({ value }) => ({
    tenants: [],
    tenantQuery: value ? value.displayName || value.name : "",
    error: null
  }),
  apollo: {
    tenants: {
      query: tenantsQuery,
      update: ({ tenants: { entries = [] } = {} }) =>
        entries
          .filter(({ name }) => name !== "delight")
          .map(({ name, displayName, ...rest }) => ({
            ...rest,
            name,
            displayName: displayName || name
          })),
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  computed: {
    organization: {
      get: ({ value }) => (value ? value.displayName || value.name : ""),
      set(value) {
        const { tenants } = this;
        const tenantName = value.trim();
        const organization = new Organization(
          tenants.find(
            ({ name, displayName }) => (displayName || name) === tenantName
          ) || { displayName: tenantName }
        );

        this.tenantQuery = tenantName;
        this.$emit("input", tenantName ? organization : null);
      }
    },
    organizations: ({ tenants, tenantQuery }) =>
      tenants
        .map(({ name, displayName }) => displayName || name)
        .filter(displayName =>
          RegExp(escapeRegExp(tenantQuery), "i").test(displayName)
        )
        .slice(0, 20)
  }
};
</script>
